<script>
  import {onMount} from 'svelte'
  import ICON from './myicons.svelte'
  import {ifiOS, isMobile} from './helpers.svelte'
  import {crossfade, scale, fade} from 'svelte/transition'
  import Comments from './Comments.svelte'
  import {CORS_PREFIX,FETCH_PREFIX} from './constants'

  const [send, receive] = crossfade({
    duration: 350,
    fallback: scale,
  })

  export let getPostByID
  export let savePostDB
  export let postDB
  export let post
  !post.myParams && (post.myParams = {})

  let loading = false
  const isWeibo = post.user.type === 'weibo'
  post.userUrl =
    (isWeibo ? 'https://m.weibo.cn/u/' : 'https://m.okjike.com/users/') +
    post.user.username
  if (!isWeibo && post.target) {
    post.targetUrl =
      (ifiOS || !isMobile ? 'https://m.okjike.com/' : 'jike://page.jk/') +
      (post.target.type === 'REPOST' ? 'repost' : 'originalPost') +
      (ifiOS || !isMobile ? 's' : '') +
      '/' +
      post.target.id
  }
  if (post.retweeted_status) {
    post.target = {
      type: 'ORIGIN',
      id: post.retweeted_status.id,
      pictures: [],
      user: post.retweeted_status.user
        ? {
            id: post.retweeted_status.user.id,
            screenName: post.retweeted_status.user.screen_name,
            avatarImage: {
              thumbnailUrl: post.retweeted_status.user.profile_image_url,
            },
          }
        : {id: null, screenName: null, avatarImag: {thumbnailUrl: null}},
      content: post.retweeted_status.text,
    }
    post.targetUrl = 'https://m.weibo.cn/status/' + post.target.id
  }

  let content,
    contentList = []
  $: {
    content = post.content
    if (content) {
      let list
      post.urlsInText &&
        post.urlsInText.forEach((urlData) => {
          if (list == undefined) {
            list = content.split(urlData.originalUrl).map((txt) => {
              return {isUrl: false, val: txt}
            })
            for (let i = list.length - 1; i > 0; i--) {
              list.splice(i, 0, {
                isUrl: true,
                val: urlData.title,
                url: urlData.url,
              })
            }
          } //if(list instanceof Array)
          else {
            list = list
              .map((frag) => {
                // console.log('frag', frag)
                let tmpL
                if (!frag.isUrl) {
                  tmpL = frag.val.split(urlData.originalUrl).map((txt) => {
                    return {isUrl: false, val: txt}
                  })
                } else {
                  tmpL = [frag]
                }
                for (let i = tmpL.length - 1; i > 0; i--) {
                  tmpL.splice(i, 0, {
                    isUrl: true,
                    val: urlData.title,
                    url: urlData.url,
                  })
                }
                return tmpL
              })
              .reduce((acc, cur) => [...acc, ...cur], [])
          }
        })
      contentList = list
      // console.log('final list', contentList)
    }
    // post.content.
  }

  let upvoted = post.upvoted ? true : false
  let showComments = false

  let postContentElm
  let nestPostInfo
  let bigImgLoaded = false,
    expanded = false,
    openYOffset
  let viewingImg

  onMount(() => {
    !post.myParams && (post.myParams = {})

    nestPostInfo = post.linkInfo
    if (nestPostInfo && nestPostInfo.audio)
      nestPostInfo.pictureUrl = nestPostInfo.audio.image.thumbnailUrl

    if (isWeibo) {
      !post.gotFullWeibo && (post.gotFullWeibo = !post.myParams.isLongText)
    } else {
      post.myParams.isLongText = post.content.length > 200
    }
  })

  function genPostUrl(id, type, isAPI = false) {
    return (
      (ifiOS || !isMobile || isAPI
        ? 'https://m.okjike.com/' + (isAPI ? 'api/' : '')
        : 'jike://page.jk/') +
      (type === 'REPOST' ? 'repost' : 'originalPost') +
      (ifiOS || !isMobile || isAPI ? 's' : '') +
      '/' +
      id
    )
  }
  async function getExtendedWeibo() {
    const url =CORS_PREFIX+ 'https://m.weibo.cn/statuses/extend?id=' +
      post.id
    // post.content = '「正在加载微博全文」' + post.content
    loading = true
    ;(await fetch(url))
      .json()
      .then((rsp) => {
        if (rsp.ok !== 1 || rsp.data.ok !== 1) {
          console.error('failed to fetch weibo detail for', post.id)
          return
        }
        post.content = rsp.data.longTextContent.replaceAll(
          '<a data-url',
          '<a target="_blank" data-url',
        )
        post.gotFullWeibo = true
        post.myParams.useOldText = true
        loading = false
        postDB[post.id] = post
        savePostDB()
      })
      .catch((err) => {
        post.myParams.isLongText = false
        post.myParams.useOldText = true
        post.gotFullWeibo = true
        loading = false
        post.content += `<a target="_blank" href="https://m.weibo.cn/detail/${post.id}">到微博App查看全文</a>`
        savePostDB()
      })
  }
  function collapseScroll() {
    const beginYOffset = postContentElm.getBoundingClientRect().y
    if (beginYOffset > 0) {
      return
    }
    window.scrollTo({top: 0})
    const newYOffset = postContentElm.getBoundingClientRect().y - 50
    console.log(postContentElm.getBoundingClientRect(), window.scrollY)
    window.scrollTo({top: newYOffset})
    // })
  }
</script>

<div class="post">
  <!-- <div class="non-pic"> -->
  <a href={post.userUrl} target="_blank">
    <img
      class="avatar"
      src={post.user.img || post.user.avatarImage.thumbnailUrl}
      alt=""
    />
  </a>
  <div class="content">
    <div class="title">
      <!-- <div> -->
      <a class="id" href={post.userUrl} target="_blank"
        >{post.user.screenName}</a
      >
      {#if post.type === 'RSS_ITEM'}
        <a href={post.id} target="_blank">
          <div class="comment-count" style="padding: 1px 8px;">more</div>
        </a>
      {:else}
        <div
          class="comment-count"
          class:weibo-badge={isWeibo}
          on:click={async () => {
            showComments = true
            let cmts
            if (post.user.type === 'weibo') {
              const rsp = await (
                await fetch(
                  FETCH_PREFIX +
                    `https://m.weibo.cn/comments/hotflow?id=${post.id}&mid=${post.id}`
                )
              ).json()
              if (rsp.ok !== 1) {
                console.log('failed to get weibo comments', post.id)
                return
              }
              const originCmts = rsp.data.data
              cmts = originCmts.map(oriCmt => {
                return {
                  //pictures,
                  content: oriCmt.text,
                  hotReplies: oriCmt.comments
                    ? oriCmt.comments.map(
                        //pictures,
                        cmt => {
                          return {
                            content: cmt.text,
                            user: { screenName: cmt.user.screen_name },
                          }
                        }
                      )
                    : [],
                  user: {
                    username: oriCmt.user.id,
                    screenName: oriCmt.user.screen_name,
                    avatarImage: {
                      thumbnailUrl: oriCmt.user.profile_image_url,
                    },
                  },
                }
              })
              //console.log('got weibo comments', cmts)
            } else {
              cmts = await fetch(
                genPostUrl(post.id, post.type, true) + '/popluarComments'
              ).then(rsp => rsp.json())
            }
            post.comments = getPostByID(post.id).comments = cmts
            savePostDB()
          }}
        >
          {post.commentCount + (isWeibo ? ' @weibo' : '')}
        </div>
      {/if}

      {#if loading}<span> 「正在加载微博全文」</span>{/if}

      <!-- {#if !isWeibo}
        <ICON
          onClick={async () => {
            upvoted = !upvoted
            getPostByID(post.id).upvoted = upvoted
            savePostDB()
          }}
          className={upvoted ? 'thumb thumb-up' : 'thumb'}
          name="thumb"
          stroke="#656567"
          style="margin: 0px 0 .3rem 1rem;
        display: flex;
        width: 22px;
        height: min-content;
        justify-content: center;
        align-items: center;"
        />
      {/if} -->
    </div>
    {#if post.title}
      <b>{post.title} </b>
    {/if}

    <div class={'post-item-content txt ' + post.type.toLowerCase()}>
      <div
        class="post-txt"
        class:expanded
        on:click={evt => {
          //console.log(evt.target.tagName, evt.target.className)
          if (
            evt.target.tagName !== 'A' &&
            evt.target.className !== 'surl-text'
          ) {
            evt.preventDefault()
            if (isWeibo) {
              //console.log('wb post', post.gotFullWeibo, post)
              if (expanded) {
                expanded = false
                collapseScroll()
              } else {
                if (post.gotFullWeibo) {
                  expanded = true
                } else {
                  getExtendedWeibo().then(() => {
                    //console.log('got wb', post.content)
                    expanded = true
                  })
                }
              }
            } else {
              if (post.myParams.isLongText) {
                if (!expanded) {
                  openYOffset = window.scrollY
                } else collapseScroll()
              }
              expanded = !expanded
            }
          }
        }}
      >
        <span bind:this={postContentElm}>
          {#if post.content && post.myParams.isLongText && !expanded}
            {@html post.content.substr(0, 170)}
          {:else if contentList}
            {#each contentList as frag}
              {#if frag.isUrl}
                <a
                  href={frag.url}
                  style="color:var(--link-color);"
                  target="_blank">{frag.val}</a
                >
              {:else}{frag.val}{/if}
            {/each}
          {:else}
            {@html post.content}
          {/if}
        </span>
        <span class="expand-notice"
          >{post.content && post.myParams.isLongText
            ? !expanded
              ? '展开'
              : '收起'
            : ''}</span
        >
      </div>
      {#if post.pictures && post.pictures.length}
        <div class={'pics num' + post.pictures.length}>
          {#each post.pictures as pic (pic.thumbnailUrl)}
            {#if viewingImg !== pic}
              <img
                in:receive={{ key: pic.thumbnailUrl }}
                out:send={{ key: pic.thumbnailUrl }}
                class:gif={pic.picUrl.substr(pic.picUrl.length - 4) === '.gif'}
                src={pic.thumbnailUrl}
                alt=""
                on:load={evt => {
                  bigImgLoaded = true
                }}
                on:click={evt => {
                  viewingImg = pic
                  viewingImg.viewingUrl = pic.thumbnailUrl
                  evt.preventDefault()
                  evt.target.src =
                    pic.picUrl.substr(pic.picUrl.length - 4) === '.gif'
                      ? pic.picUrl
                      : pic.middlePicUrl
                  evt.target.onload = () =>
                    viewingImg && (viewingImg.viewingUrl = evt.target.src)
                }}
              />
            {:else}
              <img src={pic.thumbnailUrl} style="visibility:hidden;" alt="" />
            {/if}
            {#if pic.picUrl.substr(pic.picUrl.length - 4) === '.gif'}
              <span class="gif-tag">GIF</span>
            {/if}
          {/each}
          {#if post.pictures.length > 1}
            <div class="empty-w30">lalal</div>
          {/if}
        </div>
      {/if}
      {#if 'video' in post}
        <a class={'vid'} target="_blank" href={genPostUrl(post.id, post.type)}>
          <!-- <div
            class="img"
            style={`background-image:url(${post.video.image.thumbnailUrl})`} /> -->

          <img src={post.video.image.thumbnailUrl} alt="" />
          <div class="play-btn">
            <ICON name="play" fill="#fafafa" stroke="#fafafa" />
            <div>
              {Math.floor(post.video.duration / 60000)
                .toString()
                .padStart(2, '0')}:{Math.floor(
                (post.video.duration % 60000) / 1000
              )
                .toString()
                .padStart(2, '0')}
            </div>
          </div>
        </a>
      {/if}
    </div>

    <!-- </a> -->
    {#if post.target && post.target.user}
      <a target="_blank" class="repost-target" href={post.targetUrl}>
        <img
          src={(post.target.pictures[0] &&
            post.target.pictures[0].thumbnailUrl) ||
            (post.target.user.avatarImage &&
              post.target.user.avatarImage.thumbnailUrl)}
          alt=""
        />

        <span class:weibo-origin={isWeibo}>
          <span> {post.target.user.screenName}:</span>
          <span class="repost-target-text"
            >{@html post.target.content.substring(0, isWeibo ? 300 : 60) +
              (post.target.content.length > (isWeibo ? 300 : 60)
                ? '...'
                : '')}</span
          >
        </span>
      </a>
    {/if}
    {#if nestPostInfo}
      <a class="repost-target" href={nestPostInfo.linkUrl} target="_blank">
        <img src={nestPostInfo.pictureUrl} alt="" />
        <span>{nestPostInfo.title}</span>
      </a>
    {/if}
    <!-- an error here, reason unkonwn but it works anyway-->
  </div>
</div>

{#if viewingImg}
  <!-- // 必须 await  没看懂原理 -->
  {#await viewingImg then pic}
    <!-- promise is pending -->
    <!-- {:then pic} -->
    <div
      class="img-viewer"
      out:send={{ key: pic.thumbnailUrl }}
      in:receive={{ key: pic.thumbnailUrl }}
    >
      <img
        src={pic.viewingUrl}
        alt=""
        on:click={() => {
          viewingImg = null
        }}
      />
    </div>
  {/await}
{/if}

{#if showComments}
  <Comments
    on:hideComments={() => {
      showComments = false
    }}
    on:bigImgLoaded={() => {
      bigImgLoaded = true
    }}
    on:setViewingImg={evt => {
      viewingImg = evt.detail
    }}
    {viewingImg}
    {post}
    postUrl={post.user.type === 'weibo'
      ? 'https://m.weibo.cn/status/' + post.id
      : genPostUrl(post.id)}
  />
{/if}

<style lang="scss">:root {
  --link-color: rgb(43, 113, 211);
}

:global(a) {
  color: var(--link-color);
}

.comment-count {
  margin-left: 1rem;
  transform: translateY(-2px);
  background: #ddd;
  border-radius: 3px;
  position: relative;
  min-width: 2rem;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.13rem;
}
.comment-count.weibo-badge {
  padding: 0 5px;
}
.comment-count::after {
  border-radius: 1px;
  content: "";
  transform: rotate(45deg);
  position: absolute;
  left: -3px;
  top: 5px;
  width: 6px;
  background: #ddd;
  height: 6px;
  z-index: 999;
}

.link-jike {
  border-radius: 10px;
  width: 2rem;
  height: 1.3rem;
  font-weight: bold;
  background: #ffe411;
  color: white !important;
  margin-left: 1rem;
  bottom: 3px;
  position: relative;
  text-align: center;
}

.post {
  margin: 0 0 1.2rem 0.8em;
  display: flex;
  overflow: hidden;
}
.post a {
  color: #333;
  text-decoration: none;
  height: min-content;
}

.non-pic {
  display: flex;
}

.avatar {
  margin: 0.5rem 0.5rem 0 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.content {
  max-width: 90%;
  white-space: pre-line;
  font-size: 0.95rem;
  line-height: 1.35rem;
}
.content .title {
  display: flex;
  position: relative;
  align-items: center;
}
.content .id {
  margin: 0 0 0.3rem;
}
.content .expand-notice {
  color: var(--link-color) !important;
}
.content .txt {
  display: flex;
  flex-direction: column;
}
.content .post-txt {
  margin-right: 0.8rem;
  line-break: anywhere;
}

.vid {
  position: relative;
}
.vid .play-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(2px 3px 3px black);
  flex-direction: column;
}
.vid .play-btn div {
  color: white;
}

.pics,
.vid {
  overflow: scroll hidden;
  display: flex;
  margin: 0.7rem 0 0;
  width: max-content;
  max-width: calc(100vw - 32px - 0.8rem);
  max-height: 60vw;
  border-radius: 5px;
}
.pics .img,
.pics img,
.vid .img,
.vid img {
  width: 70vw;
  height: fit-content;
  max-width: 60vw;
  max-height: 60vw;
  height: initial;
  object-fit: cover;
  margin-right: 0.6rem;
  border-radius: 5px;
}
.pics .img:last-child,
.pics img:last-child,
.vid .img:last-child,
.vid img:last-child {
  margin-right: 0.8rem;
}
.pics.num1 img,
.vid.num1 img {
  max-width: 80vw;
}
.pics.num2 img,
.vid.num2 img {
  max-width: 40vw;
}

.gif-tag {
  color: #fafafa;
  position: relative;
  font-weight: bold;
  font-size: 1.25rem;
  right: 50px;
  top: 10px;
  filter: drop-shadow(2px 3px 3px black);
  z-index: 9999;
}

.repost-target {
  line-break: anywhere;
  background: #e9e9e9;
  border-radius: 5px;
  margin-top: 0.3rem;
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  align-items: center;
  display: flex;
  max-width: 90%;
}
.repost-target img {
  max-width: 40%;
  align-self: flex-start;
  margin: 0 0.5rem 0 0;
  border-radius: 5px;
  width: 48x;
  height: 48px;
}
.repost-target span {
  font-size: 0.85rem;
  line-height: 1.1rem;
  max-height: 6.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.repost-target span.weibo-origin {
  max-height: 50vh;
}

:global(.repost-target-text) {
  pointer-events: none;
}

.pics .empty-w30 {
  width: 5px;
  height: 10px;
  visibility: hidden;
}

.pics .viewing-img {
  position: fixed;
  width: 100vw !important;
  max-width: 100vw !important;
  max-height: 94vh !important;
  z-index: 9999;
  border-radius: 0;
  left: 0;
  object-fit: contain;
}

.img-viewer > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
}

.img-viewer {
  z-index: 30000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: column;
  will-change: transform;
}

@media (prefers-color-scheme: dark) {
  :root {
    --link-color: #8db9f0;
  }

  .post a {
    color: #ececec;
  }
  .post .repost-target {
    background: #676869;
  }
  .post .expand-notice {
    color: var(--link-color) !important;
  }

  .comment-count {
    cursor: pointer;
    background: #666;
  }
  .comment-count::after {
    background: #666;
  }

  img {
    filter: brightness(0.86) !important;
  }
}</style>
