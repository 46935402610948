<script context="module">
  import { writable } from 'svelte/store'
  import { fade } from 'svelte/transition'

  export let duration = 2000

  let message = writable(''),
    isVisible = writable(false)

  export function toast(msg, _duration = duration) {
    isVisible.set(true)
    message.set(msg)

    setTimeout(() => {
      isVisible.set(false)
    }, _duration)
  }
</script>

<div>
  {#if $isVisible}
    <div class="toast" transition:fade>
      {$message}
    </div>
  {/if}
</div>

<!-- {/if} -->
<style lang="scss">.toast {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 200px;
  padding: 6px 9px;
  background-color: #fafafa;
  color: #333;
  z-index: 99999;
  box-shadow: 0px 0px 5px 0px #adacac;
}</style>
